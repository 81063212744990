import { Base, createApp, importWhenVisible } from '@studiometa/js-toolkit';
import prestashop from 'prestashop';
import { isDev } from './config.js';
import CustomerForm from './components/CustomerForm.js';
import Slider from './components/Slider.js';
import SubNav from './components/SubNav.js';
import Header from './components/Header.js';
import Communities from './components/Communities.js';
import ToggleCartSummary from './components/ToggleCartSummary.js';
import ScrollAnimationParent from './components/ScrollAnimationParent.js';
import Dropdown from './components/Dropdown.js';
import TextField from './components/TextField.js';

/**
 * App
 */
class App extends Base {
  static config = {
    log: isDev(),
    name: 'App',
    components: {
      CustomerForm,
      Slider,
      Header,
      '[data-toggle="dropdown"]': Dropdown,
      Communities,
      ToggleCartSummary,
      SubNav,
      ScrollAnimationParent,
      TextField,
      Accordion: (app) =>
        importWhenVisible(() => import('./components/Accordion.js'), 'Accordion', app),
      SelectSearch: (app) =>
        importWhenVisible(() => import('./components/SelectSearch.js'), 'SelectSearch', app),
      // TextField: (app) =>
      //   importWhenVisible(() => import('./components/TextField.js'), 'TextField', app),
    },
  };

  /**
   * Mounted.
   * Force update when cart is updated.
   * @returns {void}
   */
  mounted() {
    prestashop.on('updateCart', async () => {
      setTimeout(this.$update.bind(this), 200);
    });
  }
}

export default createApp(App, document.body);
